header{
    background: $branco;
    width: 100%;
    z-index: 999;
    @media screen and (min-width: 992px) {
        box-shadow: 0px 0px 3px #000;
    }
}
.padding-top{
    padding-top: 71px;
}
.logo{

    a{
        display: inline-block;
        
    }
}
.icon-midia{
    background: url(../img/icones/icones_redes.png);
    padding: 10px 16px 10px 16px;
    &.facebook{
        background-position: -84px -7px;
    }
    &.instagram{
        background-position: -47px -7px;
    }
    &.youtube{
        background-position: -10px -7px;
    }
    &.twitter{
        background-position: -121px -7px;
    }
    &.small{
        background-image: url(../img/icones/icones_redes_small.png);
        padding: 10px 12px 0px 12px;
        &.facebook{
            background-position: -48px 0px;
        }
        &.instagram{
            background-position: -26px 0px;
        }
        &.youtube{
            background-position: -3px 0px;
        }
        &.twitter{
            background-position: -72px 0px;
        }
    }

}
.header-head-bar{
    background-color: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    padding: 10px 0px;
    ul{
        order: 0;

        li{
            padding: 5px 5px;
        }
    }
    .btn{

        color: $branco;
        padding: 5px 25px;

        &.btn-ligar{
            background-color: darken($main-color, 10%);
            color: $main-color;
            font-weight: 600;
            text-transform: uppercase;
            font-size: .8rem;
            width: auto;
          &:hover{
              color: unset;
              background-color: unset;
            }

        }

        &.btn-ligar-header{
            background-color: unset;
            color: $main-color;
            font-weight: 600;
            text-transform: uppercase;
            font-size: .8rem;
            width: fit-content;
            padding: 5px 5px !important;
          &:hover{
              color: unset;
              background-color: unset;
            }

        }


        i{
            margin-right: 3px;
        }
    }
    .bg-white{
        background: $branco;
        margin-left: 40px;
        order: 1;

        ul{
            margin: 8px 10px 0px 10px;

            li{
                padding: 5px 0px;
            }
        }
    }
}
.bg-main-color{
    background-color: $main-color;
}
.bg-secondary-color{
    background-color: $secondary-color;
}

.header-head-info{
    ul{
        float: right;
        margin: 10px 0px;
        li{
            font-size: 14px;
            a{
                color: white;
                &.social-media{
                    //color: $main-color;
                    padding: 10px 0px;
                    //border: solid 1px $main-color;
                    //border-radius: 5px;
                    width: 10px;
                    display: inline-block;
                    text-align: center;
                    box-sizing: content-box;
                }
            }
        }
    }
}

.barra-de-pesquisa{
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
    .form-inline{
        width: 100%;
        .form-group{
            width: 100%;
            .input-group{
                border: solid 1px $branco;
                border-radius: 5px;
                width: 100%;
                input{
                    background: #fff;
                    width: 100%;
                    height: 40px;

                    outline: none;
                    box-shadow: none;
                    border: 1px solid $main-color;
                }
                input::placeholder{
                  color: $main-color;
                }
                .input-group-addon{
                    background: $main-color;
                    border: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    outline: none;
                    box-shadow: none;
                    padding: 0;

                    .border-left{
                        width: 1px;
                        height: 24px;
                        border-left: solid 1px #b7b7b7;
                        float: left;
                        margin-top: -2px;
                    }
                    .text-addon{
                        margin-top: 3px;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        background: none;
                        height: 15px !important;
                    }
                    .fa-search{
                      color:white;
                    }
                }
            }
        }
    }
}

.ofertas-header{
    margin-top: 15px;
    font-style: italic;
    font-size: 18px;
    i{
        color: red;
    }
}
.ofertas-header-mobile{
    background: #d5ab3b;

    font-style: italic;
    font-size: 12px;
    i{
        color: red;
    }
    p{
        margin: 5px 0px;
    }
}

.fa-bars{
    color: $main-color;
}

.cont-acesso{
    background: white;
    border-radius: 10px;
    padding: 0px 30px 20px 30px;
    position: absolute;
    right: -15px;
    top: 40px;
    width: 190px;
    z-index: 999;
    box-shadow: 0px 0px 15px grey;

    .arrow-up {
        position: absolute;
        top: -10px;
        right: 30px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-bottom: 10px solid white;
    }

    .col-md-12{
        margin-top: 20px;
    }

    h4{
        margin-bottom: 5px;

        .italic{
            font-style: italic;
        }
    }
}

.sombra-ab{
    background: url('../img/sombra.png');
    background-repeat: repeat-x;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 38px;
    z-index: 998;
}


/*------------------------------------*
 * #SOCIAL-MEDIAS */
/*------------------------------------ */

.social-medias {
  float: right;
  margin-top: 10px;

  @media screen and (max-width: em(1000)) {
    display: none;
  }

  &--mobile {
    float: none;
    border-left: none;
    text-align: center;
  }

  li {
    display: inline-block;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

 a {
    display: inline-block;
    width: 30px;
    height: 30px;

    @media screen and (min-width: 1000px) {
        i {
            color: $main-color;
        }
    }



/*
    background-image: url("../img/icones/iconebranco.png");

    @include transition(background 0.3s);

    &.facebook {
      background-position: 0 0;
    }

    &.instagram {
      background-position: (-38px) 0;
    }

    &.twitter {
      background-position: (-77px) 0;
    }

    &.youtube {
      background-position: (-115px) 0;
    }
  */
  }
}

/*------------------------------------*
 * #SOCIAL-MEDIAS-HEADER */
/*------------------------------------ */

.social-medias-header{
    float: right;
    display: flex;
    align-items: center;
        li{
            display: inline-block;
            a{
                color: $main-color;
            }
        }
}